<template>
    <section class="formular">
        <v-card flat v-if="document_ready" height="690">

            <template>
                <v-tabs style="flex: unset; position: fixed; max-width: 800px; z-index: 1;" class="elevation-6"
                    fixed-tabs height="32"
                    color="gold" :background-color="$vuetify.theme.dark ? '#222' : '#eee'"
                    active-class="gold lighten-1 white--text"
                >
                    <v-tab @click="switchTab(1)" class="caption">
                        <v-icon left small>mdi-text-box-check-outline</v-icon>{{ $t('purchase_reports.dialog.summary') }}</v-tab>
                    <v-tab @click="switchTab(2)" class="caption">
                        <v-icon left small>mdi-currency-eur</v-icon>{{ $t('purchase_reports.dialog.savings') }}</v-tab>
                    <v-tab @click="switchTab(3)" class="caption">
                        <v-icon left small>mdi-view-list</v-icon>{{ $t('purchase_reports.dialog.one_time_purchase') }}</v-tab>
                </v-tabs>
            </template>
            <v-card-text v-if="current_tab === 1" class="py-10">
                <v-row class="pa-4 px-0">

                    <v-col cols="12" class="py-0"
                           v-for="([key],i) in Object.entries($i18n.messages['sk'].purchase_reports.summary)"
                           :key="i"
                    >
                        <v-row no-gutters class="report-item px-2 rounded">
                            <v-col :cols="i > 11 ? 5 : 7">
                                {{ $t('purchase_reports.summary.' + key, {amount: getReportDate ? '3.5' : '4'}) }}
                            </v-col>

                            <v-col v-if="i < 12" cols="5" class="font-weight-medium" align="right">
                                <span>
                                    <span :class="{'gold--text font-weight-bold' : i > 11}">
                                        <span :class="{'gold--text font-weight-bold' : i === 5 || i === 11}">
                                            {{ g_parsePrice(purchase_report[key]) }}
                                        </span>
                                    </span>
                                </span>
                            </v-col>

                            <v-col cols="7" v-if="i > 11">
                                <v-row no-gutters>
                                    <v-col cols="4" align="right" class="">
                                        <span class="gold--text font-weight-bold">
                                            {{ g_parsePrice(purchase_report[key]) }}
                                        </span>
                                    </v-col>
                                    <v-col cols="2" align="right" class="">
                                        <span v-if="i > 17 && i < 21" class="caption">
                                            {{purchase_report[key.replace('totalB','b') + 'Percent']}}%
                                        </span>
                                    </v-col>
                                    <v-col cols="3" align="right" class="caption">
                                        {{ g_parsePrice(purchase_report[key + 'Total']) }}
                                    </v-col>
                                    <v-col cols="3" align="right" class="caption">
                                        {{ g_parsePrice(purchase_report[key + 'GrandTotal']) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="py-2" v-if="i === 5 || i === 11 || i === 17 || i === 22"/>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text v-if="current_tab === 2" class="py-10">
                <v-row class="pa-4 px-0">
                    <v-col cols="12" v-for="([key,value],i) in Object.entries($i18n.messages['sk'].purchase_reports.savings)" :key="i" class="py-0">
                        <v-row no-gutters class="report-item px-2 rounded"
                               @click="i === 9 ? dialog_ounce_price = true : ''" :style="i === 9 ? 'cursor: pointer' : ''"
                        >
                            <v-col cols="6">
                                {{ value }}
                            </v-col>
                            <v-col cols="6" class="font-weight-medium" align="right">
                                <span v-if="i === 1 || i === 2 || i === 4 || i === 10">
                                    <span :class="{'gold--text font-weight-bold' : i === 10}">{{ g_parsePrice(purchase_report[key]) }}</span>
                                </span>
                                <span v-else-if="i === 3">
                                    <span>{{ getTotalPriceTotal() }}</span>
                                </span>
                                <span v-else-if="i === 9">
                                    <v-icon left color="gold">mdi-pencil-circle-outline</v-icon>
                                    <span>{{g_parsePrice(purchase_report[key])}}</span>
                                </span>
                                <span v-else>
                                    {{ checkValue(purchase_report[key]) }}
                                    <span v-if="i === 5"> g</span>
                                    <span v-if="i === 6"> g</span>
                                    <span v-if="i === 7"> oz t</span>
                                    <span v-if="i === 8"> oz t</span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="py-2" v-if="false"/>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text v-if="current_tab === 3" class="py-10 pb-16">
                <Card card="GoldPurchaseOnceCard" :item="report.raw_data" :keys="card_headers" v-for="report in reports.items" :key="report.id" />
            </v-card-text>

            <v-dialog
                v-model="dialog_ounce_price"
                persistent
                max-width="540"
            >
                <v-alert v-if="dialog_ounce_price" class="my-0 font-weight-medium" border="left">
                    <v-row align="center">
                        <v-col class="grow">
                            <span class="body-2">{{ $t('purchase_reports.dialog.unit_ounce_price') }} </span>
                            <v-form @submit.prevent="updateOuncePrice" id="form" ref="form">
                                <v-text-field class="ounce-price" suffix="€"
                                              v-model="ounce_price" color="gold"
                                              outlined dense
                                              persistent-hint

                                              v-validate="'required|double'"
                                              data-vv-name="ounce_price"
                                              :error-messages="errors.collect('ounce_price')"
                                />
                            </v-form>

                        </v-col>
                        <v-col class="shrink">
                            <v-btn small dense depressed class="gold white--text mb-4" width="128" @click="updateOuncePrice">
                                {{ $t('common.ok') }}
                            </v-btn>
                            <v-btn small dense text outlined width="128"
                                   @click="dialogClose">
                                {{ $t('common.cancel') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>

            </v-dialog>
        </v-card>

        <div class="" :class="$vuetify.theme.dark ? 'black' : 'grey lighten-3'" style="position: absolute; bottom: 0; width: 100%;">
            <div style="width: 100%; text-align: center;">
                <v-btn
                    :loading="recomputing"
                    :disabled="recomputing"
                    color="gold" small class="my-2"
                    @click="recompute"
                >
                    <span class="white--text">{{ $t('purchase_reports.dialog.recompute') }}</span>
                    <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon dark>mdi-cached</v-icon>
                        </span>
                    </template>
                </v-btn>
            </div>
        </div>

    </section>
</template>

<script>
import {recomputeGoldPurchaseReport,updateUnitOuncePrice} from "@/api/purchase";
import Card from "@/components/Card";
import {Utils} from "@/services/utils";


export default {
    name: "GoldPurchaseReportsDialog",
    components: {
        Card
    },
    props: {
        edit: Boolean,
        id: undefined,
        card_item: undefined
    },
    data: () => ({
        dialog_detail: false,
        dialog_ounce_price: false,
        ounce_price: undefined,
        document_ready: false,
        purchase_report: undefined,
        reports: [],
        current_tab: 1,

        loader: null,
        recomputing: false,

        card_headers: [
            //{value: 'agreement_id'},
            //{value: 'customer'},
            //{value: 'type'},
            {value: 'payment'},
            {value: 'goldAmount'},
            {value: 'totalPayment'}
        ],
    }),
    created() {
        this.$validator.extend('double', {
            validate: (value) => {
                return (/^[0-9]*[.,]?[0-9]{0,2}$/g.test(value));
            }
        });

        let title = this.$t('purchase_reports.dialog.title') +' '+ this.card_item.date;
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: title});

        this.getReports();
    },
    computed: {
        getUnitOuncePrice() {
            return this.unitOuncePrice;
        },
        getReportDate() {
            let calendar = new Date('06/18/2017 00:00:00');
            let report_date = new Date(this.purchase_report.reportDate);

            return calendar.getTime() < report_date.getTime();
        }
    },
    methods: {
        switchTab(_tab) {
            this.current_tab = _tab;
        },

        checkValue(value) {
            if (value === null || value === undefined) {
                return "0"
            } else {
                return value
            }
        },

        getTotalPriceTotal() {
            return this.g_parsePrice(parseFloat(parseFloat(this.purchase_report['totalPayments'] + this.purchase_report['totalBonusPayments']).toFixed(2)))
        },

        recompute() {
            this.recomputing = true;
            recomputeGoldPurchaseReport(this.id)
                .then(()=>{
                    this.getReports();
                    this.$root.$emit('reload')
                    this.recomputing = false;
                    this.$root.$emit('notification', {
                        type: 'success',
                        icon: 'update',
                        msg: 'notification.recomputed'
                    });
                }).catch(()=>{
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.server_error'
                    });
                })
        },
        updateOuncePrice() {
            this.$validator.validate('ounce_price')
                .then((result)=>{
                    if(result) {
                        this.$root.$emit('overlay', true);
                        let new_price = this.ounce_price.toString();
                        new_price = parseFloat(new_price.replace(',','.'));
                        this.ounce_price = Utils.parsePriceValue(new_price)
                        updateUnitOuncePrice(this.id, {value: new_price})
                            .then(()=>{
                                this.$root.$emit('overlay', false);
                                this.purchase_report.unitOuncePrice = new_price;
                                this.dialog_ounce_price = false;
                                this.$root.$emit('notification', {
                                    type: 'success',
                                    icon: 'update',
                                    msg: 'notification.update_unit_ounce_price'
                                });
                            }).catch(()=>{
                                this.$root.$emit('overlay', false);
                                this.$root.$emit('notification', {
                                    type: 'error',
                                    icon: 'info',
                                    msg: 'notification.server_error'
                                });
                            })
                    } else {
                        this.$root.$emit('notification', {
                            type: 'error',
                            icon: 'error',
                            msg: 'notification.ounce_price_error'
                        });
                    }
                })

        },

        dialogClose() {
            this.dialog_ounce_price = false;
            this.validatorRefresh();
            this.ounce_price = Utils.parsePriceValue(this.purchase_report.unitOuncePrice);
        },
        validatorRefresh() {

            //let vee = this.$validator;
            this.$validator.pause();

            this.$nextTick(() => {
                this.$validator.errors.clear();
                this.$validator.fields.items.forEach(f => f.reset());
                this.$validator.fields.items.forEach(f => this.errors.remove(f));
                this.$validator.resume();
            });
        },

        getReports() {
            this.$store.dispatch('getGoldPurchaseReport',this.id)
                .then(()=>{
                    this.purchase_report = this.$store.getters.getGoldPurchaseReport;
                    this.ounce_price = Utils.parsePriceValue(this.purchase_report.unitOuncePrice);
                    this.$store.dispatch('getGoldPurchasesForReport', {report_id: this.id, filter_data: {limit:100}} )
                        .then(()=>{
                            this.reports = this.$store.getters.getGoldPurchasesForReport;
                            this.document_ready = true;
                        })
                }).catch(()=>{
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.server_error'
                    });
                })
        }
    }
}
</script>

<style>

    .report-item input,
    .ounce-price input {
        text-align: right;
    }

    .report-item:hover {
        background-color: rgba(128,128,128,0.1);
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>